import '../styles/index.scss';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
const YTPlayer = require('yt-player');

gsap.registerPlugin(ScrollTrigger);

let winW = window.innerWidth;
let winH = window.innerHeight;
let isMobile = false;
const bodyDom = document.querySelector('body');
const htmlDom = document.querySelector('html');

if (navigator.appVersion.indexOf("Win") != -1) {
  bodyDom.classList.add('is-win');
}


if (ScrollTrigger.isTouch === 1) {
  isMobile = true;
  bodyDom.classList.add('is-mobile');
}
else {
  bodyDom.classList.add('not-mobile');
}


/*******************
* VIDEO MODALS
*******************/

const modalTriggers = document.querySelectorAll('.popup-trigger');
const bodyBlackout = document.querySelector('.body-blackout');
const popupModal = document.getElementById('modal');
let player;

modalTriggers.forEach(trigger => {
  trigger.addEventListener('click', () => {
    const videoID = trigger.dataset.video;
    loadVideo(videoID);

    popupModal.querySelector('.popup-modal__close').addEventListener('click', () => {
       unloadVideo();
    });

    bodyBlackout.addEventListener('click', () => {
      unloadVideo();
    });
  });
});

function loadVideo(videoID){
  player = new YTPlayer('#player', {
    playerVars: {
      'autoplay': 0,
      'controls': 1,
      'origin': window.location.host
    }
  });
  player.load(videoID);
  player.setVolume(100);
  popupModal.classList.add('is-visible');
  bodyBlackout.classList.add('is-blacked-out');
  bodyDom.classList.add('modal-active');
  htmlDom.classList.add('modal-active');
}

function unloadVideo(){
  bodyDom.classList.remove('modal-active');
  htmlDom.classList.remove('modal-active');
  bodyBlackout.classList.remove('is-blacked-out');
  popupModal.classList.remove('is-visible');
  player.pause();
  player.destroy();
}




/*******************
* VIDEO SLIDER
*******************/
const vcClick = document.querySelector(".counter");
const vcSpeed = 0.8;

/** HORIZONTAL */
let vcCountH = 0;
const vcTargetsText = document.querySelectorAll(".video-carousel .copy li");
gsap.set(vcTargetsText, { xPercent: -100 });
gsap.set(vcTargetsText[0], { xPercent: 0 });

function slidePanelHorizontal() {
  gsap.to(vcTargetsText[vcCountH], { xPercent: 100, duration: vcSpeed });
  gsap.to(vcTargetsText[vcCountH], { opacity: 0, duration: (vcSpeed - 0.4) });
  vcCountH = vcCountH < vcTargetsText.length - 1 ? ++vcCountH : 0;
  gsap.fromTo(vcTargetsText[vcCountH], { xPercent: -100, opacity: 0, duration: vcSpeed }, { xPercent: 0, opacity: 1, duration: vcSpeed });
 }

/** VERTICAL */
let vcCountV = 0;
const vcTargetsVid = document.querySelectorAll(".video-carousel .video li");
const counterCurrent = document.querySelector(".counter .current");
const counterTotal = document.querySelector(".counter .total");
gsap.set(vcTargetsVid, { yPercent: -100 });
gsap.set(vcTargetsVid[0], { yPercent: 0 });

counterCurrent.innerHTML = 1;
counterTotal.innerHTML = vcTargetsVid.length;

function slidePanelVertical() {
  gsap.to(vcTargetsVid[vcCountV], { yPercent: 100, duration: vcSpeed });
  vcCountV = vcCountV < vcTargetsVid.length - 1 ? ++vcCountV : 0;
  gsap.fromTo(vcTargetsVid[vcCountV], { yPercent: -100, duration: vcSpeed }, { yPercent: 0, duration: vcSpeed });
  counterCurrent.innerHTML = vcCountV + 1;
 }

vcClick.addEventListener("click", nextPanel);

function nextPanel(){
  slidePanelVertical();
  slidePanelHorizontal();
}

/*******************
 * GLOBAL ANIMATION
 ********************/

/*** Reveal Text Header ***/
const textReveal = document.querySelectorAll('.revealing-header-mask-content');
textReveal.forEach((textReveal) => {
  gsap.to(textReveal, {
    scrollTrigger: {
      trigger: textReveal,
      start: 'top bottom-=50',
      toggleActions: 'play none none pause',
      onEnter: () => textReveal.classList.add('is-active'),
      onLeaveBack: () => textReveal.classList.remove('is-active')
    },
    visibility: "visible",
    opacity: 1,
    ease: "easeOut",
  });
});

/*** Icon POP ***/
const iconPop = document.querySelectorAll('.icon-pop');
let iconBottom = 'top center-=250';
let iconScrub = false;
if (isMobile){
  iconBottom = 'top bottom-=300';
  iconScrub =  true;
  if (winH < 860 && winW > 760){
    iconBottom = 'top top-=250';
  }
}
iconPop.forEach((iconPop) => {
  gsap.to(iconPop, {
    scrollTrigger: {
      trigger: iconPop,
      start: iconBottom,
      scrub: iconScrub,
      toggleActions: 'play none none pause',
      onEnter: () => iconPop.classList.add('is-active'),
      onLeaveBack: () => iconPop.classList.remove('is-active')
    },
    visibility: "visible",
    opacity: 1,
    ease: "easeOut",
  });
});


/*** GLOBAL FADE IN ***/
let fadeIn = document.querySelectorAll('.anim-fadein');
let startPoint = 'top bottom-=225';
if(isMobile){
  fadeIn = document.querySelectorAll('.mobile-fadein');
  startPoint = 'top bottom-=50';
  if (winH < 860 && winW > 760){
    startPoint = 'top center+=50';
  }
}

fadeIn.forEach((fadeIn) => {
  gsap.to(fadeIn, {
    scrollTrigger: {
      trigger: fadeIn,
      start: startPoint,
      scrub: true,
      toggleActions: 'restart none none reverse'
    },
    visibility: "visible",
    opacity: 1,
    ease: "easeOut"
  });
});


/*** GLOBAL FADE In/Slide Down ***/
const fadeDown = document.querySelectorAll('.anim-fadedown');
fadeDown.forEach((fadeDown) => {
  gsap.to(fadeDown, {
    scrollTrigger: {
      trigger: fadeDown,
      start: 'top bottom-=225',
      scrub: true,
      toggleActions: 'restart none none reverse'
    },
    y: "+=50px",
    visibility: "visible",
    opacity: 1,
    ease: "easeOut"
  });
});

/*** GLOBAL FADE In/Slide Left ***/
const fadeLeft = document.querySelectorAll('.not-mobile .anim-fadeleft');
fadeLeft.forEach((fadeLeft) => {
  gsap.to(fadeLeft, {
    scrollTrigger: {
      trigger: fadeLeft,
      start: 'top bottom+=225',
      scrub: true,
      toggleActions: 'restart none none reverse'
    },
    x: "-=50px",
    visibility: "visible",
    opacity: 1,
    duration: 2,
    ease: "easeOut"
  });
});

/*** GLOBAL FADE In/Slide Right ***/
const fadeRight = document.querySelectorAll('.not-mobile .anim-faderight');
fadeRight.forEach((fadeRight) => {
  gsap.to(fadeRight, {
    scrollTrigger: {
      trigger: fadeRight,
      start: 'top bottom+=225',
      scrub: true,
      toggleActions: 'restart none none reverse'
    },
    x: "+=50px",
    visibility: "visible",
    opacity: 1,
    duration: 2,
    ease: "easeOut"
  });
});


/*******************
 * HEADER ANIMATION
 ********************/

let videoTriggerStart = "top bottom-=80";
if (isMobile){
  videoTriggerStart = "top bottom-=60";
}
if (!isMobile){
/*** VIDEO FADES OUT  ***/
gsap.to("#hero-video", {
  scrollTrigger: {
    trigger: "#hero-video",
    toggleActions: "restart none reverse pause",
    start: "0px top",
    end: "+=130px",
    scrub: true,
    pin: true,
    pinSpacing: false
  },
  x: 0,
  opacity: 0.25,
  ease: "easeOut"
});

/*** Create Hero Scroll Trigger Timeline ***/
const homeTextFade = gsap.timeline({
  paused: true,
  scrollTrigger: {
    trigger: "#section1",
    toggleActions: "restart none reverse pause",
    start: videoTriggerStart,
    end: "+=200px",
    scrub: true
  },
});

homeTextFade
    /*** SET SECONDARY TEXT TO HIDDEN ***/
    .set("#header .secondary",{opacity: 0, visibility: "hidden"})

    /*** PRIMARY TEXT LINE 1 FADES OUT and SHIFTS ***/
    .to("#header .primary .anim-1", {
      y: "-100px",
      opacity: 0,
      duration: 2,
      ease: "easeOut"
    }, 0)
    /*** PRIMARY TEXT LINE 2 FADES OUT and SHIFTS  ***/
    .to("#header .primary .anim-2", {
      y: "+250px",
      opacity: 0,
      duration: 2,
      ease: "easeOut"
    }, 0)

    /*** SECONDARY TEXT FADES IN ***/
    .to("#header .secondary", {
      visibility: "visible",
      opacity: 1,
      duration: 3,
      ease: "easeOut"
    },.3);

}
/* END HEADER ANIMATION */



/******************************
 * HORIZONTAL SCROLL ANIMATIONS
 ******************************/

  const hPanels = gsap.utils.toArray(".section-horizontal-scroll .panels .panel");
  const hPanelParent = document.querySelector(".section-horizontal-scroll");
  const pinContent = document.querySelector(".pin-content");
  const progress = document.querySelector(".progress");
  let xPer = -100;
  if (winW > 1200) {
    xPer = -80;
  }
  if (isMobile){
    xPer = -101;
  }
  window.addEventListener('resize', () => {
    winW = window.innerWidth;
    xPer = -100;
    if (winW > 1200) {
      xPer = -80;
    }
    ScrollTrigger.clearScrollMemory();
    window.history.scrollRestoration = "manual";
  });

  gsap.to(hPanels, {
    xPercent: xPer * (hPanels.length - 1),
    ease: "none",
    scrollTrigger: {
      trigger: ".pin-content",
      start: "top-=100 top",
      end: () => "+=" + hPanelParent.offsetWidth,
      toggleActions: "restart none reverse resume",
      scrub: true,
      pin: ".pin-content",
      pinSpacing: false,
      anticipatePin: 1,
      onEnter: () => pinContent.classList.add('is-active'),
      onEnterBack: () => pinContent.classList.add('is-active'),
      onLeave: () => {
        pinContent.classList.remove('is-active');
        window.dispatchEvent(new Event('resize')); //fixed issue with pin spacing
      },
      onLeaveBack: () => pinContent.classList.remove('is-active'),
      onUpdate: self => {
        let thisPer = Math.round(self.progress * 100);
        progress.style.width = thisPer + "%";
      }
    }
  });


/*** FADE IN Long - compensate for the horizontal scroller pin spacing ***/
const fadeInLong = document.querySelectorAll('.anim-fadein-long');
fadeInLong.forEach((fadeInLong) => {
  gsap.to(fadeInLong, {
    scrollTrigger: {
      trigger: fadeInLong,
      start: "top center+=100",
      scrub: true,
      toggleActions: 'restart none none reverse',
      invalidateOnRefresh: true
    },
    visibility: "visible",
    opacity: 1,
    ease: "easeOut"
  });
});

/*** FADE IN Long - compensate for the horizontal scroller pin spacing ***/
const fadeRightLong = document.querySelectorAll('.anim-faderight-long');
fadeRightLong.forEach((fadeRightLong) => {
  gsap.to(fadeRightLong, {
    scrollTrigger: {
      trigger: fadeRightLong,
      start: "top bottom-=100",
      scrub: true,
      toggleActions: 'restart none none reverse',
      invalidateOnRefresh: true,
      end: "+=150"
    },
    visibility: "visible",
    opacity: 1,
    ease: "easeOut"
  });
});
